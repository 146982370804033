import Typewriter from "typewriter-effect";

export default function GuessMessage({ message }) {
  return (
    <div
      className='primary-text'
      style={{
        marginLeft: 30,
        marginRight: 30,
        fontSize: 16,
        fontWeight: 600,
        marginTop: 10,
        // color: 'grey'
      }}
    >
      {message}
    </div>
  );
}
