import API from "../../services/API";
import { DailyGameContext } from "@/state/DailyGameContext";
import { Input } from "@chakra-ui/react";
import { useCallback, useContext, useState } from "react";
import Guess from "./Guess";
import LoadingGuess from "./LoadingGuess";
import Globals, { MostCommonWords } from "@/constants/Globals";
import GuessMessage from "./GuessMessage";
import RealisticConfetti from "./RealisticConfetti";

export default function DailtGuessContainer() {
  const { isGuessing, currentGame, setIsGuessing, lastGuess, addGuess, guesses, setCurrentError, currentError } =
    useContext(DailyGameContext);
  const [guess, setGuess] = useState("");

  const onSubmit = useCallback(
    (word) => {
      if (!isGuessing) {
        if (MostCommonWords[word]) {
          setCurrentError(Globals.WordTooCommon);
          return;
        }
        setIsGuessing(true);

        API.guess({
          guess: word,
          game: currentGame,
        })
          .then((response) => {
            setIsGuessing(false);
            if (response.word) {
              addGuess(response);
            } else {
              setCurrentError(Globals.CouldNotFindWord);
            }
          })
          .catch((e) => {
            setCurrentError(Globals.UnkownError);
            setIsGuessing(false);
          });
      }
    },
    [isGuessing, currentGame]
  );

  return (
    <div>
      <div
        className='primary-text'
        style={{
          marginRight: 25,
          marginLeft: 25,
          fontFamily: "Noto Sans",
          fontWeight: 600,
          marginTop: 3,
          display: "flex",
          flex: 1,
          // width: 500,
          alignSelf: "stretch",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        <div>Game #{currentGame}</div>
        {guesses.length ? <div>Guesses: {guesses.length}</div> : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {lastGuess.rank === Globals.WinnerRank ? <RealisticConfetti /> : null}
        <Input
          size='lg'
          onChange={(e) => setGuess(e.currentTarget.value)}
          value={guess}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setCurrentError("");
              onSubmit(guess);
              setGuess("");
            }
          }}
          className='text-input'
          style={{
            marginLeft: 25,
            marginRight: 25,
            marginTop: 15,
            textAlign: "center",
            borderWidth: 2,
            letterSpacing: 0,
          }}
          fontFamily={"Noto Sans"}
          focusBorderColor={"blue.200"}
          placeholder='Guess a word..'
        />
      </div>

      {isGuessing ? <LoadingGuess /> : null}

      {currentError ? <GuessMessage message={currentError} /> : null}
      {lastGuess.word ? <Guess lastGuess={lastGuess} key={`last-${lastGuess.word}`} word={lastGuess.word} rank={lastGuess.rank} /> : null}
      <div
        style={{
          marginTop: 15,
        }}
      >
        {guesses.map((g, i) => {
          return <Guess lastGuess={lastGuess} key={g.word} word={g.word} rank={g.rank} idx={i} />;
        })}
      </div>
    </div>
  );
}
